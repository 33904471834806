import React, { useState, useEffect, useCallback } from 'react';
import './App.css'; // Import the default styles
import { fetchAllPicks, fetchData } from './services/api';
import { doc, getFirestore, collection, getDocs, getDoc } from 'firebase/firestore';

const TeamRankings = ({ type }) => {
    const [board, setBoard] = useState({});
    const [loading, setLoading] = useState(false);
    useEffect(() => {
        const fetchData = async () => {
            try {
                setLoading(true);
                const firestore = getFirestore();

                    var teams = collection(firestore, 'Teams');

                if(type=="ncaa"){
                    teams = collection(firestore, 'B10Teams');
                }

                // Get documents from the collection
                const snapshot = await getDocs(teams);

                // Process the snapshot
                const teamsData = snapshot.docs.reduce((acc, doc) => {
                    acc[doc.id] = doc.data();
                    return acc;
                }, {});

                console.log(teamsData);

                
                var numWeeks = 2;
                if(type=="ncaa"){
                    numWeeks = teamsData["Wolverines"].rating.length;
                }
                else{
                    numWeeks = teamsData["Vikings"].rating.length;
                }


                // Initialize the ranks for each team for all weeks
                Object.values(teamsData).forEach(team => {
                  team.weeklyRanks = Array(numWeeks).fill(0); // Create a rank array for each week
                });

                // Loop through each week
                for (let week = 0; week < numWeeks; week++) {
                  // Get teams and their rating for the current week
                  const teamsWithRatings = Object.entries(teamsData).map(([teamName, data]) => ({
                    team: teamName,
                    rating: data.rating[week],
                    city: data.city,
                    photo: data.photo
                  }));

                  // Sort by the current week's rating in descending order
                  teamsWithRatings.sort((a, b) => b.rating - a.rating);

                  // Assign rank for the current week
                  teamsWithRatings.forEach((teamData, index) => {
                    // Find the corresponding team in the original teamsData object and add the rank for the current week
                    teamsData[teamData.team].weeklyRanks[week] = index + 1; // Rank starts from 1
                  });
                }

                // Now teamsData contains both the ratings and the weeklyRanks for each week
                console.log(teamsData);

                setBoard(teamsData);
                setLoading(false);
            } catch (error) {
                console.error('Error fetching data:', error);
            }
        };

        fetchData(); // Call the function to fetch data
    }, []);

    const sortedTeams = Object.entries(board)
        .map(([key, team], index) => {
            // Calculate the result
            const ratings = team.rating || {};
            const rankings = team.weeklyRanks || {};
            const length = Object.keys(ratings).length;
            
            const lastIndex = length - 1;
            var lastRating = ratings[lastIndex] || 0;

            const ranking = rankings[lastIndex] || 0;
            const lastRanking = rankings[2] || 0;

            let result = lastRanking - ranking;

            if (lastIndex === 1) {
                result = 0;
            }

            var rating = (lastRating*2) / 2;

            // Determine the class based on the result
            const resultClass = result > 0 ? 'positive' : result < 0 ? 'negative' : '';
            result = Math.abs(result);
            result = result.toFixed(0);


            return {
                key,
                team,
                result,
                resultClass,
                rating,
                lastRating
            };
        })
        .sort((a, b) => b.lastRating - a.lastRating); // Sort by the last rating value in descending order
        console.log()
    const renderedRows = sortedTeams.map(( item, index ) => (
        <tr key={item.key}>
            <td>{index+1}</td>
            <td>
                <span>
                    <img alt="Profile" src={type === "nfl" ? item.team.photo : `https://a.espncdn.com/i/teamlogos/ncaa/500/${item.team.id}.png`} />
                    {type == "ncaa" ? item.team.college : item.team.city}&nbsp;{item.key}
                </span>
            </td>
            {/*<td className="number-col">{item.rating.toFixed(1)}</td>*/}
            {/*<td className={"number-col " + item.resultClass}>
                {item.resultClass === 'positive' ? "⭡ " + item.result : item.resultClass === 'negative' ? "⭣ " + item.result : "-"}
            </td>*/}
        </tr>
    ));

    return (
        <div className="page">
            <div className="leaderboard power">

                <h1>GIBBS {type=="ncaa" ? "B10" : ""} Power Rankings</h1>
               
                    <div>
                        <table>
                            <thead>
                            <tr>
                                <th>RK</th>
                                <th>TEAM</th>
                                {/*<th className="number-col">GPR</th>*/}
                                {/*<th className="number-col">Trend</th>*/}
                                </tr>
                            </thead>
                        <tbody>
                            {loading && Object.keys(board).length === 0 ? (
                                <tr><td colSpan="4">Loading...</td></tr>
                            ) : (
                                renderedRows
                            )}
                        </tbody>



                        </table>
                </div>
                {/*<div className="description">
                GPR (GIBBS Power Rating) is a Team's expected point margin against an average opponent on a neutral field.
            </div>*/}
        </div>
            </div>
           
    );
}


export default TeamRankings;