import React, { useState, useEffect } from 'react';
import './App.css'; // Import the default styles
import WeekButtons from './components/WeekButtons/WeekButtons';
import MatchupContainer from './components/MatchupContainer/MatchupContainer';
import { fetchData, fetchPicks, savePicks  } from './services/api';
import CountdownTimer from './components/Timer/Timer'
import {getTeams} from './Teams'; // Assuming you've placed the function in a separate file



function getCurrentDateIndex(dates) {

    const currentDate = new Date();
    const weeks = [];

    dates.forEach((dateRange, index) => {
        const [startDateStr, endDateStr] = dateRange.split(' - ');
        var year = "2024";
        if (startDateStr.includes("Jan") || startDateStr.includes("Feb")) {
            year = "2025";
        }
        const startDate = new Date(startDateStr + ", " + year);
        if (endDateStr.includes("Jan") || endDateStr.includes("Feb")) {
            year = "2025";
        }
        else {
            year = "2024";
        }
        const endDate = new Date(endDateStr + ", " + year);
        endDate.setDate(endDate.getDate() + 1); // Including end date
        weeks.push({ startDate, endDate });
    });

    var weekIndex = weeks.findIndex((week) => currentDate >= week.startDate && currentDate <= week.endDate);

    if (weekIndex === -1) {
        weekIndex = 0;
    }
    
    return weekIndex + 1;
}

const dates = [
    'Aug 1 - Sep 9',
            'Sep 10 - Sep 16',
            'Sep 17 - Sep 23',
            'Sep 24 - Sep 30',
            'Oct 1 - Oct 7',
            'Oct 8 - Oct 14',
            'Oct 15 - Oct 21',
            'Oct 22 - Oct 28',
            'Oct 29 - Nov 4',
            'Nov 5 - Nov 11',
            'Nov 12 - Nov 18',
            'Nov 19 - Nov 25',
            'Nov 26 - Dec 2',
            'Dec 3 - Dec 9',
            'Dec 10 - Dec 16',
            'Dec 17 - Dec 23',
            'Dec 24 - Dec 30',
            'Dec 31 - Jan 6',
            'Jan 7 - Jan 13',
            'Jan 14 - Jan 20',
            'Jan 21 - Jan 27',
            'May 1 - May 2',
            'Jan 28 - Feb 28',
];

const MatchupsPage = ({ db, onPageChange }) => {
    const currentDateIndex = getCurrentDateIndex(dates);
    const [matchups, setMatchups] = useState([]);
    const [selectedWeek, setSelectedWeek] = useState(currentDateIndex); // Initial selected week
    const [selectedTeamIds, setSelectedTeamIds] = useState({}); // State to track selected teams
    const [picks, setPicks] = useState([]); // Declare the 'picks' array
    const [savedPicks, setSavedPicks] = useState({}); // Add this state
    const [teams, setTeams] = useState([]); // State to hold teams

    useEffect(() => {
        // Fetch teams when the component mounts
        getTeams()
            .then((t) => {
                setTeams(t);
            })
            .catch((error) => {
                console.error('Error fetching teams:', error);
                // Handle error gracefully
            });
    }, [db]);

    const footerClick = (page) => {
        onPageChange(page); // Update the current page in the App component
    };

    // Define handleWeekClick function
    const handleWeekClick = async (week) => {
        setSelectedWeek(week);
    };

    const [isLoading, setIsLoading] = useState(false); // State to track loading status

    // Define isStarted and isEnded states
    const [isStarted, setIsStarted] = useState(false);
    const [isEnded, setIsEnded] = useState(false);
    const [isPreseason, setIsPreseason] = useState(false);

    // Define start and end states
    const [start, setStart] = useState(null);

    useEffect(() => {
        // Disable the page while fetch operations are in progress
        setIsLoading(true);
        setPicks([]);
        setSavedPicks([]);
        setSelectedTeamIds({});
        const now = new Date();


        const fetchDataAsync = async () => {
            try {
                const data = await fetchData(selectedWeek); // Adjust function name as needed

                // Sort events by date
                const sortedEvents = data.events.sort((a, b) => {
                    const dateA = new Date(a.date); // Adjust property name as needed
                    const dateB = new Date(b.date); // Adjust property name as needed
                    return dateA - dateB;
                });

                setMatchups(sortedEvents);

                setIsStarted(false);
                setIsEnded(false);
                setIsPreseason(false);

                var startTimestamp = new Date();
                var endTimestamp = new Date();
                if (selectedWeek > 18) {
                    startTimestamp = new Date(data.leagues[0].calendar[2].entries[Math.min(selectedWeek - 19, 4)].startDate);
                    startTimestamp.setDate(startTimestamp.getDate()-1);

                    console.log(data.leagues[0].calendar[2]);
                        endTimestamp = new Date(data.leagues[0].calendar[2].entries[Math.min(selectedWeek - 19, 4)].endDate);
                    }
                    else if (selectedWeek < 0) {
                        setIsStarted(false);
                        setIsPreseason(true);
                    }
                    else {
                        startTimestamp = new Date(data.leagues[0].calendar[1].entries[selectedWeek - 1].startDate);
                        endTimestamp = new Date(data.leagues[0].calendar[1].entries[selectedWeek - 1].endDate);
                    }
if (selectedWeek >= 0) {
                    startTimestamp.setHours(startTimestamp.getHours() - 7);
                    endTimestamp.setHours(endTimestamp.getHours() - 7);
                    
                    //const endTimestamp = new Date(data.leagues[0].calendar[1].entries[selectedWeek - 1].endDate);

                    
                        if (now.getTime() > (startTimestamp.getTime())) {
                            setIsStarted(true);
                        }

                        if (now.getTime() > endTimestamp.getTime()) {
                            setIsEnded(true);
                        }
                    

                        setStart(startTimestamp);
                    }
                    
                
            } catch (error) {
                console.error('Error fetching matchups:', error);
            }
        };

        const fetchPicksDataAsync = async () => {
            try {
                await fetchPicks(selectedWeek, setPicks, setSelectedTeamIds, setSavedPicks);
            } catch (error) {
                console.error('Error fetching picks:', error);
            }
        };

        Promise.all([fetchDataAsync(), fetchPicksDataAsync()])
            .then(() => {
                // Re-enable the page after both fetch operations complete
                setIsLoading(false);
            })
            .catch(error => {
                // Handle errors if any of the fetch operations fail
                console.error('Error fetching data:', error);
                setIsLoading(false); // Ensure page is re-enabled even if there's an error

            });
    }, [selectedWeek]);

    const handleTeamClick = (pick, matchupId, val, date) => {

        if ((date < new Date() || !(val > 0))) {
            return null;
        }
        else {

            setSelectedTeamIds((prevSelectedTeamIds) => {
                return {
                    ...prevSelectedTeamIds,
                    [matchupId]: { "pick": pick, "val": val },
                };
            });

            setPicks((prevPicks) => {
                const newPicks = { ...prevPicks };

                if (newPicks[matchupId] && newPicks[matchupId].pick !== pick) {
                    // If the pick for this matchupId was already saved and it's being changed, reduce savedPicks count
                    setSavedPicks((prevSavedPicks) => {
                        const updatedSavedPicks = { ...prevSavedPicks };
                        delete updatedSavedPicks[matchupId]; // Remove the old pick
                        return updatedSavedPicks;
                    });
                }

                newPicks[matchupId] = { "pick": pick, "val": val };
                return newPicks;
            });
        }
    };

    
    const confirmationDiv = document.getElementById('confirmation');

    const handleSaveClick = async () => {
        const profile = JSON.parse(localStorage.getItem('profile'));
        const user = profile.Email;
        try {
            await savePicks(picks, user, selectedWeek);
            setSavedPicks(picks);
            // Show confirmation div
            confirmationDiv.style.display = 'block';
            // Fade out after 3 seconds
            setTimeout(() => {
                confirmationDiv.style.display = 'none';
            }, 3000);
        } catch (error) {
            console.error('Error saving picks:', error);
        }
    };

    if (matchups.length < 1) {
        return <div></div>;
    }

    return (
        <div>
            <WeekButtons status={`${isLoading ? 'loading' : ''}`} handleWeekClick={handleWeekClick} />
            <div className={!isStarted ? (isPreseason ? `page preseason unclickable` :  `page unclickable`) : `page`}>
            {isLoading && (
                <div>
                    
                    <div className="loader"></div>
                </div>
            )}
            {!isLoading && (
                    <>
                        {!isStarted ? (
                            !isPreseason ? (
                                <div className="banner">
                                    OPENS IN <CountdownTimer start={start} />
                                </div>
                            ) : (
                                <div className="banner">
                                    PRESEASON - PICKS DISABLED
                                </div>
                            )
                        ) : isEnded ? (
                            null
                            /*<div className="banner">WEEK CLOSED</div>*/
                        ) : null}
                        {selectedWeek == 23 ? (
                            <div className="matchup-container dark-news" onClick={() => footerClick(`nfl-teams`)}>
                                <div className="image"><img src="Group 17.png" /></div>
                                <div className="content"><div className="heading">Final Team Rankings</div>
                                    <div className="description">Click to View</div></div>
                            </div>
                        ) : null}
                        {matchups.map((matchup) => (
                            <MatchupContainer
                                key={matchup.id}
                                preseason={isPreseason}
                                matchup={matchup}
                                handleTeamClick={handleTeamClick}
                                selectedTeamIds={selectedTeamIds}
                                teams={teams}
                                onPageChange={onPageChange}
                                type="nfl"

                            />
                        ))}

                        <div id="confirmation">Picks Saved.</div>

                       {((!isEnded && isStarted) || false) ? (
                        <div className="save-section">
                            <div className="save-subsection">
                                    
                                    <button id="mainsave" onClick={handleSaveClick}>
                                        Update Picks
                                    </button>
                            </div>
                            <div className="save-subsection">
                                    <div className="pickProgress">
                                        <svg xmlns="http://www.w3.org/2000/svg" height="40" width="40" viewBox="0 0 20 20">
                                                <circle r="5" cx="10" cy="10" stroke="#666" strokeWidth="5" />
                                                <circle r="5" cx="10" cy="10" stroke="#aaa" strokeWidth="5" strokeDasharray={`calc(${100 * Object.keys(picks).length / matchups.length} * 31.42 / 100) 31.42`} transform="rotate(-90) translate(-20)" />
                                                <circle r="5" cx="10" cy="10" stroke="white" strokeWidth="5" strokeDasharray={`calc(${100 * Object.keys(savedPicks).length/matchups.length} * 31.42 / 100) 31.42`} transform="rotate(-90) translate(-20)" />

                                                <circle r="5" cx="10" cy="10" strokeWidth="2" fill={Object.keys(savedPicks).length == matchups.length ? "white" : "black"} />
                                          <line x1="7" y1="10.5" x2="9.25" y2="12.75" strokeWidth="1" />
                                           <line x1="9" y1="12.5" x2="13" y2="8.5" strokeWidth="1" />
                                        </svg>
                                        {Math.min(Object.keys(savedPicks).length, 16)}/{Math.min(matchups.length, 16)} Saved
                                    </div>
                                
                            </div>
                        </div>) : null }
                    </>

            )}
            </div>
        </div>
    );
};

export default MatchupsPage;
