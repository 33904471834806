import React, { useEffect, useState, useRef } from 'react';
import './Playoff.css';

const More = ({ onPageChange, league }) => {

    const [teams, setTeams] = useState([]);
    const [refresh, setRefresh] = useState(0);
    const [loading, setLoading] = useState(false);
    const [UPSET_INDEX, setUPSET_INDEX] = useState(1000);
    const [week, setWeek] = useState(20);


    useEffect(() => {
        const fetchData = async () => {
            setLoading(true)
            try {console.log("here");
                // Fetch ratings data
                const ratingsResponse = await fetch('http://localhost:3001/api/ratings/fpi');
                const spResponse = await fetch('http://localhost:3001/api/ratings/sp');
                const talentResponse = await fetch('http://localhost:3001/api/talent');
                const gameResponse = await fetch('http://localhost:3001/api/games');

                /*const ratingsResponse = await fetch('http://localhost:3001/api/ratings/fpi2');
                const spResponse = await fetch('http://localhost:3001/api/ratings/sp2');
                const talentResponse = await fetch('http://localhost:3001/api/talent2');
                const gameResponse = await fetch('http://localhost:3001/api/games2');*/
        
                const ratingsData = await ratingsResponse.json();
                const spData = await spResponse.json();
                const talentData = await talentResponse.json();
                const gameData = await gameResponse.json();
                
                // Combine data
                const combinedData = {};

                // Process ratings data
                ratingsData.forEach(team => {
                    if (!combinedData[team.team]) {
                        
                        combinedData[team.team] = {
                            team: team.team,
                            conference: team.conference,
                            rating: (team.fpi + 50),
                            value: 0,
                            talent: 0,
                            avg: (team.fpi + 50),
                            wins: 0,
                            gp: 0,
                            BestWin: "",
                            BestWinVal: 0,
                            WorstLoss: "",
                            WorstLossVal:1000
                        };

                        
                    }
                });

                //console.log(ratingsData);

                // Process talent data and merge with ratings data
                talentData.forEach(team => {
                    if (combinedData[team.school]) {
                        //combinedData[team.school].talent = team.talent/10;
                        combinedData[team.school].avg = 1 * ((combinedData[team.school].rating * .8 + 1 * team.talent / 10) / 2).toFixed(1);

                        
                        //combinedData[team.school].avg = Math.pow(combinedData[team.school].avg*combinedData[team.school].avg*combinedData[team.school].avg/100000;

                        if (combinedData[team.school].avg < 70) {
                            combinedData[team.school].value -= 4;
                           // combinedData[team.school].avg = combinedData[team.school].avg + (100 - combinedData[team.school].avg) / 3;
                        }
                        else if (combinedData[team.school].rating < 70) {
                           // combinedData[team.school].value -= 5;
                          // combinedData[team.school].avg = combinedData[team.school].avg - (60 - combinedData[team.school].avg) / 1.5;
                        }
                        if (team.school == "Army" || team.school == "Navy" || team.school == "Air Force") {
                            combinedData[team.school].avg = .75 * ((combinedData[team.school].rating));
                        }

                        combinedData[team.school].avg = Math.max(combinedData[team.school].avg, 1);
                    }
                });

                spData.forEach(team => {
                    if (combinedData[team.team]) {
                        combinedData[team.team].avg += team.rating / 5;
                    }
                });

                console.log(combinedData);

                // Convert combinedData to an array and sort by avg
                const sortedData = Object.keys(combinedData).map(key => ({
                    team: key,
                    ...combinedData[key]
                })).sort((a, b) => b.avg - a.avg); // Sort in descending order
                
                
                //const UPSET_INDEX = 3;
                const Windex =7;
               // console.log(UPSET_INDEX);
                // Simulate the season



                gameData.forEach(game => {
                    if(game.week < week){
                        if (game.home_team && game.away_team) {
                            const homeTeam = combinedData[game.home_team];
                            const awayTeam = combinedData[game.away_team];
                        
                            var HomeWinner = true;

                            if (game.completed) {
                                if (game.home_points > game.away_points) {
                                    HomeWinner = true;
                                    if (homeTeam && awayTeam) {
                                        /*homeTeam.avg += awayTeam.avg / 1000;
                                        awayTeam.avg -= (100 - homeTeam.avg) / 1000;*/
                                    }
                                 }
                                 else{
                                    HomeWinner = false;
                                    if (homeTeam && awayTeam) {
                                       /* homeTeam.avg -= (100 - awayTeam.avg) / 1000;
                                        awayTeam.avg += homeTeam.avg / 1000;*/
                                    }
                                }
                            }
                            else{
                                if (homeTeam && awayTeam) {
                                    const randomness = Math.random();
                                    const UI = 0;
                                    //HomeWinner = homeTeam.avg + UI*Math.random() + 5 > awayTeam.avg + UI * Math.random();
                                    HomeWinner = homeTeam.avg + 4 + UI * Math.random() > awayTeam.avg + UI * Math.random();
                                    //console.log(homeTeam.avg + awayTeam.avg);
                                    const winsout = "Michigan State!";
                                    if (game.home_team == winsout) {
                                        HomeWinner = true;
                                        console.log(game.away_team + " " + HomeWinner);
                                    }else if(game.away_team == winsout){
                                        console.log(game.home_team + " " + !HomeWinner);
                                        console.log(homeTeam.avg - awayTeam.avg);
                                        HomeWinner = false;
                                    }
                                }
                                else if(awayTeam){
                                    HomeWinner = false;
                                }
                            }
                          
                            
                       
                        
                            if (homeTeam && awayTeam) {

                                if (awayTeam.value > homeTeam.BestWinVal && HomeWinner) {
                                    homeTeam.BestWin = awayTeam.team;
                                    homeTeam.BestWinVal = awayTeam.value;
                                }

                                if (homeTeam.value > awayTeam.BestWinVal && !HomeWinner) {
                                    awayTeam.BestWin = homeTeam.team;
                                    awayTeam.BestWinVal = homeTeam.value;
                                }
                                
                                //awayTeam.BestWin = homeTeam.team;
                                homeTeam.gp += 1;
                                awayTeam.gp += 1;

                                var hVal = 2;
                                if (homeTeam.avg >= 80) {
                                    hVal += 3;
                                }
                                if (homeTeam.avg >= 70) {
                                    hVal += 2;
                                }
                                if (homeTeam.avg >= 60) {
                                    hVal += 2;
                                }

                                var aVal = 2;
                                if (awayTeam.avg >= 80) {
                                    aVal += 3;
                                }
                                if (awayTeam.avg >= 70) {
                                    aVal += 2;
                                }
                                if (awayTeam.avg >= 60) {
                                    aVal += 2;
                                }

                                if (HomeWinner) {
                                    homeTeam.wins += 1;

                                    //homeTeam.value += aVal;
                                    //awayTeam.value += hVal - 10;
                                    

                                   homeTeam.value += Windex / 1 + Math.pow(awayTeam.avg/6, 2)/10;
                                    awayTeam.value -= Windex / 1 + Math.pow(10 - homeTeam.avg/10, 2) / 2;

                                    homeTeam.avg += awayTeam.avg / 100;
                                    awayTeam.avg -= (100 - homeTeam.avg) / 100;
                                } else {

                                    awayTeam.wins += 1;

                                    //awayTeam.value += hVal;
                                    //homeTeam.value += aVal - 10;

                                    homeTeam.avg -= (100 - awayTeam.avg) / 100;
                                    awayTeam.avg += homeTeam.avg / 100;

                                    awayTeam.value += Windex / 1 + Math.pow(homeTeam.avg/6, 2) / 10;
                                    homeTeam.value -= Windex / 1 + Math.pow(10 - awayTeam.avg/10, 2) / 2;
                                    
                                }
                            }
                            else if (homeTeam) {
                                homeTeam.gp += 1;
                                homeTeam.wins += 1;
                                homeTeam.value += Windex / 20 + week / 10;
                                if (homeTeam.BestWin == "") {
                                    homeTeam.BestWin = "FCS";
                                }
                            }
                            else if (awayTeam) {
                                awayTeam.gp += 1;
                                awayTeam.wins += 1;
                                awayTeam.value += Windex / 20 + week / 10;
                                if (awayTeam.BestWin == "") {
                                    awayTeam.BestWin = "FCS";
                                }
                            }

                        }
                        }
                });

                const updatedSortedData1 = Object.keys(combinedData).map(key => ({
                    team: key,
                    ...combinedData[key]
                })).sort((a, b) => (1 * b.wins) - (1 * a.wins));

                const firstTeamsByConference = {};
                const secondTeamsByConference = {};
                var num = 4;
                

                Object.values(updatedSortedData1).forEach((team, index) => {
                    const conference = team.conference;
                    //team.value -= 15*Math.max(0, team.gp-team.wins-1);
                    /*if(conference == "SEC"){
                        team.value += 97;
                    }
                    if(conference == "Big Ten"){
                        team.value += 100;
                    }
                    if(team.team == "Ohio State"){
                        team.value += 10;
                    }
                    if(team.team == "Alabama"){
                        team.value -= 10;
                    }
                    if(conference == "Pac-12"){
                       // team.value += 19;
                    }
                    if(conference == "ACC"){
                        team.value += 100;
                    }
                    if(conference == "Big 12"){
                        team.value += 100;
                    }
                    if(conference == "FBS Independents"){
                        team.value += 100;
                    }
                    if(conference == "Pac-12"){
                        team.value += 100;
                    }
                    if (conference == "Mountain West") {
                        team.value += 135;
                    }
                    if (conference == "American Athletic") {
                        team.value += 75;
                    }
                    if (conference == "Conference USA") {
                        team.value += 0;
                    }*/
                    if (week > 16 && false) {
                        // SEC
                        if (team.team == "Georgia") {
                            team.value += 40;
                            team.gp++;
                            team.wins++;
                        }
                        if (team.team == "Texas") {
                            team.gp++;
                            team.value -= 10;
                        }

                        // Big Ten
                        if (team.team == "Ohio State") {
                            team.value += 40;
                            team.gp++;
                            team.wins++;
                        }
                        if (team.team == "Oregon") {
                            team.gp++;
                            team.value -= 10;
                        }

                        // ACC
                        if (team.team == "Miami") {
                            team.value += 40;
                            team.gp++;
                            team.wins++;
                        }
                        if (team.team == "SMU") {
                            team.gp++;
                            team.value -= 10;
                        }

                        // Big 12
                        if (team.team == "Arizona State") {
                            team.value += 40;
                            team.gp++;
                            team.wins++;
                        }
                        if (team.team == "Iowa State") {
                            team.gp++;
                            team.value -= 10;
                        }

                        
                        if (conference != "FBS Independents" && conference != "Pac-12" && !(conference == "Big 12" || conference == "ACC" || conference == "SEC" || conference == "Big Ten") && true){
                            
                            if (!firstTeamsByConference[conference] && firstTeamsByConference[conference] != 0) {
                                team.gp++;
                                firstTeamsByConference[conference] = index;
                            } else if (!secondTeamsByConference[conference]) {
                                team.gp++;
                                //console.log(team);
                                //console.log(team.avg - updatedSortedData1[firstTeamsByConference[conference]].avg);
                                if (team.avg > updatedSortedData1[firstTeamsByConference[conference]].avg) {
                                    team.wins++;
                                    
                                    team.value += updatedSortedData1[firstTeamsByConference[conference]].avg / 1.5;
                                    updatedSortedData1[firstTeamsByConference[conference]].value -= 10;
                                }
                                else {
                                    updatedSortedData1[firstTeamsByConference[conference]].wins++;
                                    updatedSortedData1[firstTeamsByConference[conference]].value += team.avg / 1.5;
                                    team.value -= 10;
                                }
                                num--;
                                secondTeamsByConference[conference] = team;
                            }
                        }
                        }
                });
                


                //console.log(updatedSortedData1);
                // Display the updated sorted data with win counts
                const updatedSortedData = Object.keys(updatedSortedData1).map(key => ({
                    team: key,
                    ...updatedSortedData1[key]
                })).sort((a, b) => (b.value / (b.gp + 12) * 2 + 1 * b.avg / (week)) - (a.value / (a.gp + 12) * 2 + 1*a.avg / (week))); // Sort in descending order again
                
                //console.log(updatedSortedData);
                setTeams(updatedSortedData);
            } catch (error) {
                console.error('Error fetching combined data:', error);
            }
            setLoading(false);
        };

        fetchData();

    }, [refresh]);

    const simulate = (r, ui) => {
        setRefresh(r);
        setUPSET_INDEX(ui);
    }

    

    return (
        <div className="more page">
            <div className="AppGameList leaderboard">
                <div className="Games">
                <div onClick={() => onPageChange("newGame")} className="Game B10"><div className="Gamelogo"><img src="b10_logo.png" /></div>
                        <div className="Gametitle">GIBBS B10</div>
                        <div className="Gamedescription">GIBBS, but for the Big Ten</div></div>
                    <div onClick={() => onPageChange("auctionGame")} className="Game QBX">
                        <div className="Gamelogo"><img src="qx.png" /></div>
                        <div className="Gametitle">QBX</div>
                        <div className="Gamedescription">Stock market game</div>
                    </div>
                   
                <div onClick={() => onPageChange("cfp")} className="Game CFP"><div className="Gamelogo"><img src="cfp.png" /></div>
                        <div className="Gameinfo">
                            
                                    <div className="Gametitle">CFP Projection</div>
                                    <div className="Gamedescription"><br/>Look at the latest College Football Playoff Projection created by GIBBS Analytics<br/><br/><i>Updated Every Week</i></div>
                            </div>
                        </div>
               

                    <div onClick={() => onPageChange("wrapped")} className="Game Wrapped">
                    <div className="Gameinfo">
                        <div className="Gametitle">GIBBS Wrapped</div>
                        <div className="Gamedescription"><br />Check out your picking preferences for the 2024/25 season!</div>
                    </div>
                </div>
                </div>
                {<><h1>NCAAF 2024 Simulation</h1>
                {!loading ? (
                    <div className="">
                        <table>
                            <thead>
                                <tr>
                                    <th>RK</th>
                                    <th>Team</th>
                                    <th>GPR</th>
                                        <th>SOR</th>
                                        <th>Total</th>
                                        <th>CONF</th>
                                    <th>Record</th>
                                </tr>
                            </thead>
                            <tbody>
                                {teams.map((team, index) => (
                                    team.team != "nationalAverages" && team.conference !== "Big Ten!" && <tr key={team.team}>
                                        <td>{index + 1}</td>
                                        <td><b>{team.team}</b></td>
                                        <td>{(team.avg * 1).toFixed(0)}</td>
                                        <td>{(team.value).toFixed(0)}</td>
                                        <td>{(team.value / (team.gp + 12) * 2 + 1 * team.avg / week).toFixed(1)}</td>
                                        <td>{team.conference}</td>
                                        <td>{team.wins} - {team.gp - team.wins}</td>
                                    </tr>
                                ))}
                            </tbody>
                        </table>
                    </div>
                ) : (
                    <div>Refreshing...</div>
                )}
                </>}
            </div>
        </div>
    );
}

const listOfTeams = {
  "Georgia": 61,
  "Michigan": 130,
  "Ohio State": 194,
  "Alabama": 333,
  "LSU": 99,
  "Penn State": 213,
  "Florida State": 52,
  "USC": 30,
  "Washington": 264,
  "Texas": 251,
  "Tennessee": 2633,
  "Clemson": 228,
  "Notre Dame": 87,
  "Oregon": 2483,
  "Utah": 254,
  "Kansas State": 2306,
  "Oklahoma": 201,
  "North Carolina": 153,
  "Ole Miss": 145,
  "Wisconsin": 275,
  "TCU": 2628,
  "Texas A&M": 245,
  "Missouri": 142,
  "Iowa": 2294,
  "UCLA": 26,
  "Oklahoma State": 197,
  "Tulane": 2655,
  "Kentucky": 96,
  "South Carolina": 2579,
  "Arkansas": 8,
  "Auburn": 2,
  "Mississippi State": 344,
  "Minnesota": 135,
  "Texas Tech": 2641,
  "Purdue": 2509,
  "Maryland": 120,
  "Illinois": 356,
  "Baylor": 239,
  "Louisville": 97,
  "Nebraska": 158,
  "Iowa State": 66,
  "Miami (FL)": 2390,
  "Boise State": 68,
  "Oregon State": 204,
  "Pittsburgh": 221,
  "BYU": 252,
  "Duke": 150,
  "Wake Forest": 154,
  "UCF": 2116,
    "Liberty": 2335,
    "UTSA": 2636,
    "Miami": 2390,
    "Kansas": 2305,
    "Arizona State": 9,
    "Toledo": 2649,
    "UNLV": 2439,
    "SMU": 2567,
    "Arizona": 12,
    "Colorado": 38,
    "James Madison": 256,
    "Indiana": 84,
    "Army": 349,
    "Washington State": 265,
    "Memphis": 235,
    "Syracuse":183
};
// UP: "&#11105;"
    // NEW: +
    // DOWN: &#11107;

const teams = [
    { seed: 1, name: 'Oregon', trend: "", record: "(13-0)" },
    { seed: 2, name: 'Georgia', trend: "", record: "(12-1)" },
    { seed: 3, name: 'Boise State', trend: "", record: "(12-1)" } ,
    { seed: 4, name: 'Arizona State', trend: "", record: "(12-1)" } ,
    { seed: 5, name: 'Texas', trend: "", record: "(12-1)" } ,
    { seed: 6, name: 'Penn State', trend: "", record: "(10-2)" },
    { seed: 7, name: 'Notre Dame', trend: "", record: "(12-1)" },
    { seed: 8, name: 'Ohio State', trend: "", record: "(11-2)" },
    { seed: 9, name: 'Tennessee', trend: "", record: "(10-2)" } ,
    { seed: 10, name: 'Indiana', trend: "", record: "(10-2)" } ,
    { seed: 11, name: 'SMU', trend: "", record: "(10-2)" } ,
    { seed: 12, name: 'Clemson', trend: "", record: "(11-2)" } 
];

const teamsArray = ["Clemson", "Iowa State", "Indiana", "Tennessee", "Tulane"];
/*

const teams = [
    { seed: 1, name: 'Ohio State', trend: "", record: "(13-0)" },
    { seed: 2, name: 'Georgia', trend: "&#11105;", record: "(12-1)" },
    { seed: 3, name: 'Miami', trend: "", record: "(12-1)" } ,
    { seed: 4, name: 'Boise State', trend: "", record: "(12-1)" } ,
    { seed: 5, name: 'Oregon', trend: "", record: "(12-1)" } ,
    { seed: 6, name: 'Penn State', trend: "", record: "(11-1)" },
    { seed: 7, name: 'Notre Dame', trend: "", record: "(11-1)" },
    { seed: 8, name: 'Texas', trend: "", record: "(12-1)" },
    { seed: 9, name: 'Tennessee', trend: "+", record: "(10-2)" } ,
    { seed: 10, name: 'Indiana', trend: "&#11105;", record: "(11-2)" } ,
    { seed: 11, name: 'Clemson', trend: "+", record: "(10-2)" } ,
    { seed: 12, name: 'Arizona State', trend: "+", record: "(12-1)" } 
];
const teamsArray = ["SMU", "Iowa", "Indiana", "Iowa State", "Utah"];
*/
const allTeams = [
    { seed: 1, name: 'Oregon', record: "13-0*", criteria: "279" },
    { seed: 2, name: 'Georgia', record: "11-2*", criteria: "276" },
    { seed: 3, name: 'Texas', record: "11-2", criteria: "269" },
    { seed: 4, name: 'Penn State', record: "11-2", criteria: "255" },
    { seed: 5, name: 'Notre Dame', record: "11-1", criteria: "249" },
    { seed: 6, name: 'Ohio State', record: "11-2", criteria: "248" },
    { seed: 7, name: 'Tennessee', record: "10-2", criteria: "240" },
    { seed: 8, name: 'Indiana', record: "11-1", criteria: "233" },
    { seed: 9, name: 'Boise State', record: "12-1", criteria: "233" },
    { seed: 10, name: 'SMU', record: "11-2", criteria: "225" },
    { seed: 11, name: 'Alabama', record: "9-3", criteria: "225" },
    { seed: 12, name: 'Arizona State', record: "11-2*", criteria: "222" },
    { seed: 13, name: 'Miami', record: "11-2", criteria: "222" },
    { seed: 14, name: 'Ole Miss', record: "9-3", criteria: "222" },
    { seed: 15, name: 'South Carolina', record: "9-3", criteria: "212" },
    { seed: 16, name: 'Clemson', record: "10-3*", criteria: "208" },
    { seed: 17, name: 'BYU', record: "10-2", criteria: "204" },
    { seed: 18, name: 'Iowa State', record: "10-3", criteria: "202" },
    { seed: 19, name: 'Missouri', record: "9-3", criteria: "198" },
    { seed: 20, name: 'Illinois', record: "9-3", criteria: "191" },
    { seed: 21, name: 'Syracuse', record: "9-3", criteria: "186" },
    { seed: 22, name: 'Army', record: "11-1", criteria: "194" },
    { seed: 23, name: 'Colorado', record: "9-3", criteria: "178" },
    { seed: 24, name: 'UNLV', record: "10-3", criteria: "180" },
    { seed: 25, name: 'Memphis', record: "10-2", criteria: "178" }
];

const getTeamLogoUrl = (id) => `https://a.espncdn.com/combiner/i?img=/i/teamlogos/ncaa/500/${id}.png&w=50&h=50`;

const rounds = [
  [1, 8, 9],
  [4, 5, 12],
  [2, 7, 10],
  [3, 6, 11]
];

const PlayoffProjection = () => {
  return (
      <div className="more page">
            <div className="AppGameList leaderboard">
              <div className="playoff-projection"><br />
                  <h5><i>Updated December 5th at 8:00 AM.</i></h5>
      <div className="bracket">
        {rounds.map((round, roundIndex) => (
          <div className="round" key={roundIndex}>
            {round.map((seed, matchIndex) => {
              const team = teams.find(t => t.seed === seed);
              if (matchIndex === 0) {
                // If it's the first team in the round, it's a bye
                return (
                  <div className="matchup" key={matchIndex}>
                    <div className="bye">
                      <div className="team">
                        <div className="teamInfo">
                          <span className="seed">{team.seed}</span>
                          <img src={getTeamLogoUrl(listOfTeams[team.name])} alt={team.name} />&nbsp;{team.name} {/*team.record*/} <span dangerouslySetInnerHTML={{ __html: team.trend }} />
                        </div>
                      </div>
                    </div>
                  </div>
                );
              } else if (matchIndex === 1) {
                // It's a regular matchup
                const nextTeamSeed = round[matchIndex + 1];
                const nextTeam = teams.find(t => t.seed === nextTeamSeed);

                return (
                  <div className="matchup" key={matchIndex}>
                    <div className="first">
                      <div className="team">
                        <div className="teamInfo">
                          <span className="seed">{team.seed}</span>
                          <img src={getTeamLogoUrl(listOfTeams[team.name])} alt={team.name} />&nbsp;{team.name} {/*team.record*/} <span dangerouslySetInnerHTML={{ __html: team.trend }} />
                        </div>
                      </div>
                      {nextTeam && (
                        <div className="team">
                          <div className="teamInfo">
                            <span className="seed">{nextTeam.seed}</span>
                            <img src={getTeamLogoUrl(listOfTeams[nextTeam.name])} alt={nextTeam.name} />&nbsp;{nextTeam.name} {/*nextTeam.record*/} <span dangerouslySetInnerHTML={{ __html: nextTeam.trend }} />
                          </div>
                        </div>
                      )}
                    </div>
                  </div>
                );
              }
            })}
          </div>
        ))}
      </div>
              </div>
              {/*<BubbleTeams teams={teamsArray} />*/}
              {/*<h2 style={{ textAlign: "center" }}>Dropped out: <img src={getTeamLogoUrl(listOfTeams["Clemson"])} /> <img src={getTeamLogoUrl(listOfTeams["LSU"])} /> <img src={getTeamLogoUrl(listOfTeams["Kansas State"])} /> <img src={getTeamLogoUrl(listOfTeams["Boise State"])} /></h2>
  <h5 style={{textAlign: "center"}}>*Signifies Conference Champion</h5>*/}
    <br />

              <div>
                  <table>
                      <thead>
                          <tr>
                              <th>RK</th>
                              <th>Team</th>
                              <th>Record</th>
                              {/*<th>Points</th>*/}
                          </tr>
                      </thead>
                      <tbody>
                          {allTeams.map((team, index) => (
                              <tr key={index}>
                                  <td>{team.seed}</td>
                                  <td><div className="table-team"><img src={getTeamLogoUrl(listOfTeams[team.name])} alt={team.name} />&nbsp;{team.name}</div></td>
                                  <td>{team.record}</td>
                                  {/*<td>{team.criteria}</td>*/}
                              </tr>
                          ))}
                      </tbody>
                  </table>

              </div>
     </div>
      </div>
  );
};

const BubbleTeams = ({ teams }) => {
    return (
        <h2 style={{ textAlign: "center" }}>
            Bubble Teams:{" "}
            {teams.map((team) => (
                <div className="bubble"><img key={team} src={getTeamLogoUrl(listOfTeams[team])} alt={team} /></div>
            ))}
        </h2>
    );
};

export { More, PlayoffProjection };
